<template>
  <div>
    <p class="text-subhead-3 mb-1">{{ itemsTypeMap.get(type).title }}</p>
    <div class="text-body-2 text-black-80">
      <span class="text-subhead-4">{{ data.lead_ids.length }} leads </span>
      <template v-if="data.loading"
        >are being prepared for {{ itemsTypeMap.get(type).description }}. We will notify you when the process is
        complete. This may take some time.</template
      >
      <template v-else>
        were successfully {{ itemsTypeMap.get(type).completed }}. You can open these leads in a new tab.

        <UiButtonBase id="goToLeads" type="secondary" size="small" class="mt-2" @click="goToLeads">
          Open leads
        </UiButtonBase>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
const emits = defineEmits(['close'])
type Props = {
  data: any
  type: string
}

const props = defineProps<Props>()

const itemsTypeMap = new Map<string, any>([
  ['reopen_and_assign', { title: 'Lead assignment', description: 'reassignment', completed: 'reassigned' }],
  ['release_to_pool', { title: 'Release to Pool', description: 'release to Pool', completed: 'released to Pool' }],
])

const goToLeads = () => {
  const params = props.data.lead_ids.map((l: number) => `lead_ids=${l}`).join('&')
  const link =
    props.data.lead_ids.length > 1
      ? `/pipelines/${props.type === 'release_to_pool' ? 'pool' : 'sales'}?${params}`
      : `/leads/${props.data.lead_ids[0]}`
  navigateTo(link, {
    open: {
      target: '_blank',
    },
  })
  emits('close')
}
</script>

<style scoped></style>
